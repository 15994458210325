.mapboxgl-ctrl-geocoder {
   min-width: 100%;
   max-width: 760px !important;
   width: 90% !important;
   margin-right: auto !important;
   margin-left: auto !important;
}
.mapboxgl-ctrl-geocoder > input {
   padding-left: 20px;
   padding-right: 10px;
   font-size: 18px !important;
}
.mapboxgl-popup-close-button {
  display: none;
}

.mapboxgl-popup-content {
  font: 400 15px/22px 'Source Sans Pro', 'Helvetica Neue', sans-serif;
  padding: 0;
  max-width: 180px;
}

.mapboxgl-popup-content h3 {
  margin: 0;
  padding: 5px;
  border-radius: 3px 3px 0 0;
  font-weight: 700;
  margin-top: -5px;
}


.mapboxgl-popup-content h4 {
  margin: 0;
  padding: 5px;
  font-weight: 400;
}

.mapboxgl-popup-content div {
  padding: 10px;
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-content {
  margin-top: 15px;
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-tip {
  border-bottom-color: #91c949;
}
